var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //'id',
          _vm.helpers.monthName,
          _vm.helpers.rounding,
          _vm.helpers.createdAt
        ],"config":{
          url: 'admin/property_roundings',
          route: '/admin/parameters/property_roundings',
          name: 'property_rounding',
          params: { _lists: 'months,roundings' },
          duplicate: true,
          latest: 'month_id'
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }